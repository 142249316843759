<template>
  <div>
    <b-button-group class="mb-2">
      <b-button
        v-for="item in menu"
        :key="item.name"
        :variant="currentMenu === item.name ? 'primary': 'outline-primary' "
        @click="currentMenu = item.name"
      >
        {{ item.label }}
        <b-badge
          v-if="item.name === 'pending'"
          variant="danger"
        >
          {{ unmatchedTotal }}
        </b-badge>
        <b-badge
          v-if="item.name === 'done'"
          variant="danger"
        >
          {{ doneTotal }}
        </b-badge>
      </b-button>
    </b-button-group>
    <div>
      <component
        :is="selectedComponent"
        :type="'deposit'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Deposit transactions'
  },
  components: {
    PendingTransactions: () => import('./components/TransactionPending.vue'),
    DoneTransactions: () => import('./components/TransactionDone.vue'),
  },
  data() {
    return {
      toggleStatus: true,
      currentMenu: 'pending',
      menu: [ {name: 'pending', label: 'รอดำเนินการ'}, {name:'done', label: 'ดำเนินการแล้ว'} ],
      currentPage: 1,
      selectedLimit: 20,
      selectedNote: '',
      intervalIdentifier: null,
      intervalTime: 10000
    }
  },
  computed: {
    ...mapGetters(['depositDone', 'depositUnmatched']),
    ...mapState({
      intervalFetch: (state) => state.credit.intervalFetch,
    }),
    unmatchedTotal() {
      return this.depositUnmatched.meta.totalItems
    },
    doneTotal() {
      return this.depositDone.meta.totalItems
    },
    selectedComponent() {
      switch (this.currentMenu) {
        case 'pending':
          return 'PendingTransactions'
        case 'done':
          return 'DoneTransactions'
        default:
          return ''
      }
    },
  },
  watch: {
    toggleStatus(val) {
      if (val) {
        this.startInterval()
      } else {
        this.stopInterval()
      }
    },
  },
  created() {
    this.fetchData()
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval()
  },
  methods: {
    ...mapActions([
      'fetchDepositDone',
      'fetchDepositUnmatched',
    ]),
    startInterval() {
      this.intervalIdentifier = setInterval(() => {
        this.fetchDepositUnmatched({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: '',
          agentId: '',
          masterId: '',
        })
      }, this.intervalTime)
    },
    stopInterval() {
      clearInterval(this.intervalIdentifier)
    },
    fetchData() {
      this.fetchDepositUnmatched({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        agentId: '',
        masterId: '',
      })
      this.fetchDepositDone({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: '',
        agentId: '',
        masterId: '',
      })
    },
  },
}
</script>
